<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Reportes</h4>
            <div class="small text-muted">Reportes de ventas</div>
          </b-col>                        
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12">
          <b-card>
            <b-row >
              <b-col sm="10">            
                <b-row>            
                  <b-col sm="12" class="pb-1">
                    <b-row>         
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                      </b-col>
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                      </b-col> 
                      <b-col sm="3" class="p-1 pb-1">
                        <v-select :options="arr.filters.status_imputation" v-model="filters.status_imputation" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col>                    
                    </b-row>
                  </b-col>         
                  <b-col sm="12">
                    <b-row>
                      <b-col sm="3" v-if="moduleSellersActive" class="p-1">
                        <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>                                    
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>  
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col> 
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="2" class="p-1">
                <b-row>
                  <b-col sm="12">
                    <b-button variant="outline-dark" @click="loadByFilter()" size="sm" class="pull-right">
                      <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                      Filtrar
                    </b-button>
                  </b-col>                       
                </b-row>
              </b-col>
            </b-row>   
          </b-card>
        </b-col>
      </b-row>
      
      <b-tabs class="mb-3">         
        <b-tab title="Ventas">
          
          <Pivot :dataJson="pivot.data" v-if="pivot.data"/> 

        </b-tab>          
      </b-tabs>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>
    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import moment from 'moment'  
  import Pivot from '@/components/inc/pivot/index'

  export default {     
    components: {
      Pivot,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'reports',
          elements: {}
        },  
        pivot: {
          data: null,
        },
        arr: {
          filters : {
            customers: [],
            sellers: [],
            points_sales: [],
            status_imputation: [
              {code: true, label: 'Cancelado'},
              {code: false, label: 'Pendiente'}
            ]
          },                       
        },
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          points_sales: null,
          status_imputation: null,
          paginate: false,
        },  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()   
      
      setTimeout(()=>{
        this.loadByFilter()
      },200)
    },
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },
    },    
    methods: {
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filterLoadPointSale()   
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {         
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                             
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },       
      loadByFilter() {
        var result = serviceAPI.filtrarComprobante(this.filters)

        result.then((response) => {
          var data = response.data

          this.pivot.data = this.processPivot(data)
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });        
      },

      processPivot(data) {
        var addData = []        
        data.forEach(element => {
          addData.push({
            date: element.date,
            staff: (element.staff) ? element.staff.name : '',
            seller: (element.seller) ? element.seller.name: 'Sin vendedor',
            type_voucher: (element.type_voucher) ? element.type_voucher.name : '',
            point_sale: (element.point_sale) ? element.point_sale.point_sale : '',
            customer: (element.customer) ? element.customer.name : '',
            number: element.number,            
            amount_total: (element.type_voucher.type_balance == '+') ? parseFloat(element.amount_total) : parseFloat(element.amount_total) * -1,
          })
        });

        return {
          dataSource: {                  
            data: addData,
          },
          slice: {
            rows: [
              {
                uniqueName: "seller",
                sort: "asc",
              },                 
              {
                uniqueName: "staff",
                sort: "asc",
              },              
              {
                uniqueName: "customer",
                sort: "asc",
              },                            
            ],
            columns: [
              {
                uniqueName: "date.Month",
                sort: "asc",
              },                  
            ],
            measures: [
              {
                uniqueName: "amount_total",
                aggregation: "sum"
              },                                   
            ],
            expands: {
              expandAll: false,
              rows: [],
              columns: []
            },
            drills: {
              drillAll: false
            }
          },
          options: {
            grid: {
              type: "compact",
              title: "",
              showFilter: true,
              showHeaders: true,
              showTotals: false,
              showGrandTotals: "off",
              showHierarchies: true,
              showHierarchyCaptions: true,
              showReportFiltersArea: true
            },
            configuratorActive: false,
            configuratorButton: true,
            showAggregations: true,
            showCalculatedValuesButton: true,
            drillThrough: true,
            showDrillThroughConfigurator: true,
            sorting: "on",
            datePattern: "dd/MM/yyyy",
            dateTimePattern: "dd/MM/yyyy HH:mm:ss",
            saveAllFormats: false,
            showDefaultSlice: true,
            defaultHierarchySortName: "asc"
          },
          formats: [
            {
              name: "",
              thousandsSeparator: " ",
              decimalSeparator: ".",
              decimalPlaces: 2,
              maxSymbols: 20,
              currencySymbol: "",
              currencySymbolAlign: "left",
              nullValue: " ",
              infinityValue: "Infinity",
              divideByZeroValue: "Infinity"
            }
          ],
          localization: "https://cdn.webdatarocks.com/loc/es.json"                     
        }    
      },        
    }    
  }
</script>